<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, defineExpose } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['getCouponCodeSuc'])

  const data = reactive({
    loading: false,
    centerDialogVisible: false,
    redeemCode: ''
  })

  const dialogShow = () => {
    data.centerDialogVisible = true
  }

  const getCouponCode = () => {
    console.log(data.redeemCode, 'data.redeemCode')
    api.coupon
      .getCouponCode(data.redeemCode, userStore.userInfo.userId)
      .then((res) => {
        console.log('兑换验证码', res)
        data.centerDialogVisible = false
        proxy.$toast('succeed', { type: 'succeed' })
        $emit('getCouponCodeSuc')
      })
      .catch((res) => {
        data.centerDialogVisible = false
        console.log('兑换验证码err', res, res.title)
        proxy.$toast('The redemption code does not exist.', { type: 'error' })
      })
  }

  defineExpose({
    dialogShow
  })
</script>

<template>
  <el-dialog v-model="data.centerDialogVisible" title="Redeem Coupon" width="500" center class="dialog-box">
    <div v-loading="data.loading">
      <div class="lable">Instructions</div>
      <div class="txt">
        Enter the redemption code on the current page to get the corresponding coupons. One redemption code can only be redeemed for one coupon and cannot be used again.
      </div>
      <div class="coupon-code-box rowCC">
        <input class="coupon-code-input" type="text" placeholder="Please Enter the coupon code here" v-model="data.redeemCode" />
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="getCouponCode" round> Redeem </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="less" scoped>
  .dialog-box {
    font-size: 14px;
    border-radius: 24px;
    .lable {
      color: var(--btnBgColor);
    }
    .txt {
      margin-top: 12px;
      line-height: 24px;
    }
    .coupon-code-box {
      width: 100%;
      margin: 24px auto 0;
      cursor: pointer;
      .coupon-code-input {
        width: 240px;
        text-align: center;
        line-height: 30px;
        border: none;
        border-bottom: 1px solid #ccc;
      }
      .coupon-code-input:focus {
        border-bottom: 1px solid var(--btnBgColor); /* 设置聚焦时的下划线颜色 */
      }
    }
  }
</style>
